import React from 'react'
import { Link } from 'react-router-dom';
import { FaUserCircle } from 'react-icons/fa';
import { FaBars } from "react-icons/fa6";
import { IoClose } from "react-icons/io5";
import './Navbar.scss'
const Navbar = ({handlelogout ,toggleSidebar ,isSidebarOpen ,togglecontactModel ,isLoggedIn}) => {
  
  return (
  
      <div className="navbar">
       <Link to='/home' style={{ textDecoration: "none", color: "white" }}> <div className="nav-logo">TheCodingshef</div></Link>
        <div className="nav-items">
       
          <li className="list-item">
            <Link to="/course" style={{ textDecoration: "none", color: "white" }}>Courses</Link>
          </li>
          <li className="list-item" onClick={togglecontactModel}>Contact</li>
        {/* {isLoggedIn ? ' ' :  <Link to="/signup" style={{ textDecoration: "none", color: "white" }}><li className="list-item" style={{marginRight:"1rem"}}>Create Account</li></Link>}
          <div className="profile" style={{display:"flex" ,justifyContent:"center",alignItems:"center" , marginLeft:"1rem"}}>
       
       {isLoggedIn ? (
         <>
         <FaUserCircle style={{fontSize:"1.5rem",color:"white",marginRight:"1.5rem"
}} className="profile-icon" />
           <button onClick={handlelogout} className='logout-btn'>Logout</button>
         </>
       ) : ' '}
       </div> */}
        </div>
        
{/*         
  <button className="sidebar-toggle" onClick={toggleSidebar}>
            {isSidebarOpen ? <IoClose className='close' /> : <FaBars className='open' />}
          </button> */}
      </div>
  
  )
}

export default Navbar
