import React, { useState } from 'react';
import './Homepage.scss';


import compressazim from '../asset/icons/compressazim.jpeg'
import { FaLinkedin } from "react-icons/fa6";
import frontimg from '../asset/icons/frontimg.png';
import EmployeeSlider from '../Sliders/EmployeeSlider';
import { motion, AnimatePresence } from 'framer-motion';
import { RiArrowDropDownLine, RiCloseLine } from "react-icons/ri";
import { Link } from 'react-router-dom';

import SessionSlider from '../Sliders/SessionSlider';

import { useNavigate } from 'react-router-dom';
import Workshop from '../Workshop/Workshop';
import Navbar from '../Common/Navbar';
import Privacy from '../Common/Privacy';
import Contact from '../Common/Contact';
import Model from '../Common/Model';
import Footer from '../Common/Footer';
export default function Homepage() {
  // const { isLoggedIn } = useContext(LogContext);
 
  const [openQuestion, setOpenQuestion] = useState(null);
  const [isTermsOpen, setIsTermsOpen] = useState(false);
  const [isPrivacy, setIsPrivacy] = useState(false);
  const[isHelp ,setIsHelp]=useState(false)

  
  const toggleQuestion = (index) => {
    setOpenQuestion(openQuestion === index ? null : index);
  };

  const toggleTermsModal = () => {
    setIsTermsOpen(!isTermsOpen);
   
// setIsHelp(!isHelp)
  };


  const togglePrivacyModel=()=>{
    setIsPrivacy(!isPrivacy)
  }


  const togglecontactModel=()=>{
    setIsHelp(!isHelp)
  }


  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => setIsSidebarOpen(!isSidebarOpen);


  const navigate = useNavigate();

 
  return (
    <div className='Home-container'>
      {/* <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar}  handlelogout={handlelogout}/> */}
      <Navbar
       
       togglecontactModel={togglecontactModel}
     
      />

      {/* Render the Contact Modal */}
      {isHelp && (
        <Contact togglecontactModel={togglecontactModel}/>
      )}

      {/* front main part */}
      <div className="front-section">
        <div className="frontpart">
          <div className="right-front">
            <p className="front-heading">6 Month To Launch Your Career 🔥</p>
            <h1 className="front-main__heading">
              WebShef <span style={{ color: "orange" }}>1.0</span>
            </h1>
            <p className="front-last__heading">
              The Most Affordable Program For Front-End Mastery
            </p>
            <Link to="/course" style={{ textDecoration: "none", color: "white" }}>
            <motion.button
              className="btn-joinnow"
              whileHover={{ scale: 1.1 }}
             
              transition={{
                duration: 2,
               
                ease: "easeInOut"
              }}
              style={{ marginTop: "2rem" }}
            >
             
              Join Now
            </motion.button>
            </Link>
          </div>

          <div className="left-front">
            <div className="left-main__card-sec">
              <img className="main-image" src={frontimg} alt="" />
            </div>
          </div>
        </div>
      </div>



      {/*  Live workshops */}

      <Workshop/>

      {/* Screenshot autoslider */}
      <div className="auto-slider">
        <div className="slider">
          <div className="slider-heading">
            <h1 className="heading">What Students Love About Us?</h1>
          </div>
          <div className="slide-section">
            <EmployeeSlider />
          </div>
        </div>
      </div>

      {/* Mentor section */}
      <div className="Mentor-section">
        <div className="Mentorpart">
          <div className="right-front">
            <h1 className="mentor-main__heading">
              Connect with us for Personal Guidance
            </h1>
           <a href="https://topmate.io/azim_sidd"> <button className="btn">1:1 Session</button></a>
          </div>
          <div className="left-front">
            <div className="card-mentor">
              <img className="image-mentor" src={compressazim} alt="" />
              <div className="description"><p>Azim Siddiqui</p>
              <p> Senior Softwere Engineer</p>
             <a href="https://www.linkedin.com/in/azimsidd/"><FaLinkedin  className='linkedin' style={{fontSize:"2rem" , marginTop:".7rem",color:"orange"}}/></a> 
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* top mate session slider */}
      <div className="session-slider">
        <div className="session">

        <div className="session-heading">
       <h1 className="heading" style={{marginTop:"8rem"}}>1:1 Session Reviews</h1>
           
          </div>
       
          <div className="session-slider__container">
            <SessionSlider />
          </div>
        </div>
      </div>

      {/* FAQ section */}
      <div className="question-section">
        <div className="question">
          <div className="question-heading">
            <h1 className="heading" style={{marginTop:"8rem"}}>FAQ's</h1>
          </div>
          <div className="faq-item">
            <div className="main-question">
              <div onClick={() => toggleQuestion(0)} className="faq-question">
                <h3 className="react-heading">Will react be covered in this course?</h3>
                <div className="icon"><RiArrowDropDownLine className="icon-dropdown" /></div>
              </div>
              <AnimatePresence initial={false}>
                {openQuestion === 0 && (
                  <motion.div
                    animate={{ height: "auto", opacity: 1 }}
                    transition={{ duration: 0.2, ease: "easeInOut" }}
                    className="faq-answer"
                  >
                    <p className="react">Yes, we will cover all react concepts in this course.</p>
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
          </div>
          <div className="faq-item">
       <div className="main-question">
       <div onClick={() => toggleQuestion(1)} className="faq-question">
         <h3 className="react-heading"> What are prerequisites of this course</h3>
         <div className="icon"><RiArrowDropDownLine className="icon-dropdown"/></div>
        </div>
      
        <AnimatePresence initial={false}>
        {openQuestion === 1 && (
          <motion.div
               animate={{ height: "auto", opacity: 1 }}
          
          transition={{ duration: 0.2, ease: "easeInOut" }}
           className="faq-answer">
           <p className="react"> A laptop with internet connection, Hunger for Learning. Ready for a grind.

        </p>
        </motion.div>

        )}
        </AnimatePresence>
       </div>
      </div>
     {/*  third */}
     <div className="faq-item">
       <div className="main-question">
       <div onClick={() => toggleQuestion(2)} className="faq-question">
         <h3 className="react-heading"> What language will the course be taught in</h3>
         <div className="icon"><RiArrowDropDownLine className="icon-dropdown"/></div>

        </div>
      
        <AnimatePresence initial={false}>
        {openQuestion === 2 && (
          <motion.div
           
           animate={{ height: "auto", opacity: 1 }}
          
          transition={{ duration: 0.2, ease: "easeInOut" }} className="faq-answer">
           <p className="react"> mixup of Hindi + English
        </p>
        </motion.div>

        )}
        </AnimatePresence>
       </div>
      </div>

      {/* 4orth */}
      <div className="faq-item">
       <div className="main-question">
       <div onClick={() => toggleQuestion(3)} className="faq-question">
         <h3 className="react-heading">Is the course recorded or live</h3>
         <div className="icon"><RiArrowDropDownLine className="icon-dropdown" /></div>

        </div>
        <AnimatePresence initial={false}>
        {openQuestion === 3 && (
          <motion.div
           
           animate={{ height: "auto", opacity:1 }}
          
          transition={{ duration: 0.2, ease: "easeInOut" }} className="faq-answer">
           <p className="react">The course will be live and the recorded lecture we will provide you after class
        </p>
        </motion.div>

        )}
        </AnimatePresence>
       </div>
      </div>

      {/* fifith */}
      <div className="faq-item">
       <div className="main-question">
       <div onClick={() => toggleQuestion(4)} className="faq-question">
         <h3 className="react-heading"> Will you cover node-js </h3>
         <div className="icon-dropdown"><RiArrowDropDownLine className="icon-dropdown" /></div>
        </div>
      
        <AnimatePresence initial={false}>
        {openQuestion === 4 && (
          <motion.div
          
            animate={{ height: "auto" ,opacity:1}}
         
            transition={{ duration: 0.2, ease: "easeOut" }} className="faq-answer">
           <p className="react"> This is an intensive frontend React web series, we will not be covering Node.js.
        </p>
        </motion.div>

        )}
        </AnimatePresence>
       </div>
      </div>

          {/* Add more FAQs as needed */}
        </div>
      </div>

      {/* Footer section */}
     <Footer/>



    </div>
  );
}
