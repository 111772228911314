import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Workshopform.scss';
import axios from 'axios';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Button,
    CircularProgress,
} from '@mui/material';

function Workshopform() {
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [name, setName] = useState('');
    const [year, setYear] = useState('');
    const [country, setCountry] = useState(''); // New state for country

    const [nameError, setNameError] = useState('');
    const [emailError, setEmailError] = useState('');
    const [phoneError, setPhoneError] = useState('');
    const [yearError, setYearError] = useState('');
    const [countryError, setCountryError] = useState(''); // New error state for country

    const [dialogOpen, setDialogOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false); // For button loading state

    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Reset error messages
        setNameError('');
        setEmailError('');
        setPhoneError('');
        setYearError('');
        setCountryError(''); // Reset country error

        let isValid = true;

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const phoneRegex = /^[0-9]{10}$/;

        // Basic validation
        if (!name) {
            setNameError('This field is required');
            isValid = false;
        }

        if (!email) {
            setEmailError('This field is required');
            isValid = false;
        } else if (!emailRegex.test(email)) {
            setEmailError('Enter a valid email address');
            isValid = false;
        }

        if (!phone) {
            setPhoneError('This field is required');
            isValid = false;
        } else if (!phoneRegex.test(phone)) {
            setPhoneError('Enter a valid 10-digit phone number');
            isValid = false;
        }

        if (!year) {
            setYearError('This field is required');
            isValid = false;
        }

        if (!country) {
            setCountryError('This field is required'); // Validate country
            isValid = false;
        }

        if (!isValid) return;

        const formData = { Name: name, Email: email, Phone: phone, Year: year, Country: country }; // Include country in formData

        setIsLoading(true); // Show loading spinner

        try {
            const response = await axios.post(
                'https://api.sheetbest.com/sheets/917e7f38-fc1a-4ba6-b59a-4de4b8e82338',
                formData,
                { headers: { 'Content-Type': 'application/json' } }
            );
            console.log('API Response:', response.data);

            // Show success dialog
            setDialogOpen(true);
        } catch (error) {
            console.error('Error details:', {
                message: error.message,
                response: error.response?.data,
                status: error.response?.status,
            });
            alert('Registration failed. Please try again.');
        } finally {
            setIsLoading(false); // Hide loading spinner
        }
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
        navigate('/home'); // Redirect to home after dialog closes
    };

    const handleJoinWhatsApp = () => {
        window.open('https://chat.whatsapp.com/EEsf6sGnCHdDCymdEtp8KM', '_blank'); // Redirect to WhatsApp group
        setDialogOpen(false); // Close the dialog
    };

    return (
        <div className="workshopform-section">
            <div className="workshopform-form">
                <div className="form-container">
                    <div className="login-fields">
                        <h3 className="form-heading">Register</h3>
                        <div className="form-fields">
                            <form onSubmit={handleSubmit}>
                                <div className="name">
                                    <p>Name<sup style={{ color: "red" }}>*</sup></p>
                                    <input
                                        type="text"
                                        value={name}
                                        placeholder="Enter your name"
                                        className="form"
                                        onChange={(e) => setName(e.target.value)}
                                    />
                                    {nameError && <p className="error-message">{nameError}</p>}
                                </div>
                                <div className="signup-email">
                                    <p>Email<sup style={{ color: "red" }}>*</sup></p>
                                    <input
                                        type="email"
                                        value={email}
                                        placeholder="Enter your email"
                                        className="form"
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                    {emailError && <p className="error-message">{emailError}</p>}
                                </div>
                                <div className="year">
                                    <p>Graduation Year<sup style={{ color: "red" }}>*</sup></p>
                                    <input
                                        type="text"
                                        value={year}
                                        placeholder="Enter your graduation year"
                                        className="form"
                                        onChange={(e) => setYear(e.target.value)}
                                    />
                                    {yearError && <p className="error-message">{yearError}</p>}
                                </div>
                                <div className="number">
                                    <p>Phone number<sup style={{ color: "red" }}>*</sup></p>
                                    <input
                                        type="tel"
                                        value={phone}
                                        maxLength={10}
                                        placeholder="Enter your phone number"
                                        className="form"
                                        onChange={(e) => {
                                            const onlyNums = e.target.value.replace(/\D/g, ''); // Allow only digits
                                            setPhone(onlyNums); // Set phone number state with only digits
                                        }}
                                        onKeyPress={(e) => {
                                            if (!/[0-9]/.test(e.key)) { // Prevent typing non-numeric characters
                                                e.preventDefault();
                                            }
                                        }}
                                    />
                                    {phoneError && <p className="error-message">{phoneError}</p>}
                                </div>
                                <div className="country">
                                    <p>Country<sup style={{ color: "red" }}>*</sup></p>
                                    <input
                                        type="text"
                                        value={country}
                                        placeholder="Enter your country"
                                        className="form"
                                        onChange={(e) => setCountry(e.target.value)}
                                    />
                                    {countryError && <p className="error-message">{countryError}</p>}
                                </div>
                                <div className="button">
                                    <button type="submit" className="login-btn" disabled={isLoading}>
                                        {isLoading ? <CircularProgress size={24} color="inherit" /> : 'Register'}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            {/* Success Dialog */}
            <Dialog open={dialogOpen} onClose={handleDialogClose}>
                <DialogTitle>Registration Successful</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        You have successfully registered for the workshop! Join this group for more updates.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleJoinWhatsApp} color="primary">
                        Join WhatsApp Group
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default Workshopform;
