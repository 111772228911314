import React from 'react';


const Card = ({title,current,prev,button,course,point1,point2,point3,point4,point5,image ,price ,icon ,type ,card ,link}) => {
  return (
    <div>
       <div className="left-front">
            <div className="left-front__card">
              <div className="card-first__section">
                <img src={image} alt="" className="image" />
              </div>

              <div className="card-second__section">
                <h3 className="card-second__heading">
                 {title}
                </h3>
                <div className="card-price__section">
                  <div className="price">
                  {type ? (
  <>
    <div className="current">{current}</div>
    <div className="prev-price">{prev}</div>
  </>
):<div style={{fontSize:"1.5rem"}}>{price}</div> } 
                  </div>
                
                </div>
                 <a href={link} ><button 
     className="card-buy__course-btn">{button}</button></a>
                <div className="course-include">
                <h3 style={{color: "#666"}}>{course}</h3>
{card && (<><div className="point"><p>{point1}</p></div>

<div className="point">{icon}<p> {point2}</p></div>
<div className="point">{icon}<p>{point3}</p></div>
<div className="point">{icon}<p>{point4}</p></div>
<div className="point">{icon}<p>{point5}</p></div></>)}
                 
         
                </div>

              </div>

            </div>
          </div>
    </div>
  )
}

export default Card


