import React, { useState } from "react";
import "./Main.scss";
import {  RiCloseLine } from "react-icons/ri";

import { motion, AnimatePresence } from 'framer-motion';
import { RiArrowDropDownLine } from "react-icons/ri";
import Banner from '../asset/icons/Banner.jpeg'
import './Homepage.scss'

import { FaBookOpen } from "react-icons/fa6";
import { BsPersonVideo } from "react-icons/bs";
import { AiOutlineSend } from "react-icons/ai";
import { RiPagesLine } from "react-icons/ri";
import { CiVideoOn } from "react-icons/ci";
import { MdOutlineStickyNote2 } from "react-icons/md";
import { AiFillSafetyCertificate } from "react-icons/ai";
import { FaLinkedin } from "react-icons/fa";
import { FaTv } from "react-icons/fa6";
import { FaConnectdevelop } from "react-icons/fa6";
import { FaGift } from "react-icons/fa";
import { FaMoneyBillWave } from "react-icons/fa";
import { AiOutlineFundProjectionScreen } from "react-icons/ai";
import { FaGithub } from "react-icons/fa";
import { MdOutlineBrandingWatermark } from "react-icons/md";
import { FaAddressCard } from "react-icons/fa";
import ProjectSlider from "../Sliders/ProjectSlider";


import { Link } from "react-router-dom";
import Privacy from "../Common/Privacy";
import { Modal } from "@mui/material";
import Contact from "../Common/Contact";
import Footer from "../Common/Footer";
import Card from "../Common/Card";
export default function Main() {

const data=[
    {
        skill:"HTML",
      
    },
    {
        skill:"CSS",
       

    },
    {
        skill:"Javascript",
        
    },
    {
        skill:"React",
        
    },
    {
        skill:"Typescript",
        
    },
   
    {
        skill:"Bootstrap"
    },
    {
        skill:"Material Ui"
    },
    {
        skill:"Git /Github"
    },
    {
        skill:"DSA in JS"
    },
    {
        skill:"Interview Questions"
    },

    {
        skill:"System Design"
    },
    
    {
        skill:"Firebase "
    },
    {
        skill:"Tailwind CSS"
    },
   
   
]





const cardData = {
  title: " Complete Frontend Development Course (Projects + Roadmap)",
  current:"₹3,499",
  prev:"₹6,999",
  button:"Buy Course",
  course:"COURSE INCLUDES :",
  point1:"No prerequisite Required",
  point2:" Best Projects",
  point3:"Alternate days classes",
  point4:"Sunday Doubt classes",
  point5:"Live Sessions",
  icon:<AiOutlineSend  className="point-icon"/>,
  image: Banner,
  type:true,
  card:true,
  link:"https://pages.razorpay.com/webshef1"
};

const[skill ,setSkill]=useState(data)

//  toggle

const [openQuestion, setOpenQuestion] = useState(null);

  const toggleQuestion = (index) => {
  
    if (openQuestion === index) {
      setOpenQuestion(null);
    } else {
      setOpenQuestion(index);
    }
  };

  
  const [isTermsOpen, setIsTermsOpen] = useState(false);
  const [isPrivacy, setIsPrivacy] = useState(false);
  const[isHelp ,setIsHelp]=useState(false)
  
  const toggleTermsModal = () => {
    setIsTermsOpen(!isTermsOpen);
   
// setIsHelp(!isHelp)
  };


  const togglePrivacyModel=()=>{
    setIsPrivacy(!isPrivacy)
  }


  const togglecontactModel=()=>{
    setIsHelp(!isHelp)
  }
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  // const toggleSidebar = () => setIsSidebarOpen(!isSidebarOpen);

  return (
    <div className="container">
     {/* <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} togglecontactModel={togglecontactModel} /> */}
      {/* navbar */}

      <div className="navbar">
        <div className="nav-logo"><Link style={{textDecoration:"none" ,color:"orange"}} to="/home">TheCodingshef</Link></div>
        <div className="nav-items">
          <li className="list-item"><Link style={{textDecoration:"none" ,color:"orange" , fontWeight:"500"}} to="/home">Home</Link></li>
          <li className="list-item" onClick={togglecontactModel}>Contact</li>
          {/* <button className="sidebar-toggle" onClick={toggleSidebar}>
            {isSidebarOpen ? <IoClose className='close' /> : <FaBars className='open' />}
          </button> */}
         
        </div>
      </div>

      {/* frontpart */}

      <div className="front-section">
        <div className="frontpart">
          {/*  front right part */}

          <div className="right-front">
            <p className="front-heading">6 Month To Launch Your Career 🔥</p>

            <h1 className="front-main__heading">
              WebShef <span style={{ color: "orange" }}>1.0</span>
            </h1>

            <p className="front-last__heading">
              The Most Affordable Programme For Front-End Mastery
            </p>
<p className="front-mini-heading">3 Month Extensive Training + 3 Month Internship</p>

            <div className="time-grid">
<div className="from-time__grid"><div className="icon"><CiVideoOn  className="time-icon"/></div><p>Live Classes</p></div>
<div className="from-time__grid-seclast"><div className="icon"><RiPagesLine  className="time-icon2"/></div><p>Projects</p></div>
<div className="from-time__grid"><div className="icon"><BsPersonVideo  className="time-icon3"/></div><p>Doubt classes</p></div>
<div className="from-time__grid-last"><div className="icon"><FaBookOpen className="time-icon4" /></div><p>Assignment</p></div>
            </div>
          </div>

          {/*  front left part */}
        <Card 
          title={cardData.title}
          current={cardData.current}
          prev={cardData.prev}
          button={cardData.button}
          course={cardData.course}
          point1={cardData.point1}
          point2={cardData.point2}
          point3={cardData.point3}
          point4={cardData.point4}
          point5={cardData.point5}
          icon={cardData.icon}
          image={cardData.image}
          type={cardData.type}
          card={cardData.card}
          link={cardData.link}
        />
          
        </div>
      </div>


      {/* secons section */}

      <div className="learn-section">
        <div className="learn">
        {/* first div */}
            <div className="learn-first__part">
<h1 className="learn-first__heading">What you will learn</h1>
<h3 className="learn-second__heading">Bhout Kuch Sikha Denge 🚀 </h3>
            </div>

{/* second div */}
<div className="learn-grid">

{/*  ist skill */}


{
    skill.map((ele)=>{
        return(
            <>
            <div className="learn-items">
    <div className="mark"></div> 
    <div className="learn-skill">
        <p className="skill">{ele.skill}</p>
    </div>
    </div>
            </>
        )
    })
}
    
</div>
        </div>
      </div>

      {/*  third section perks and benefits */}

      <div className="perks-section">
      <div className="perk-benefits">

      <div className="perks">
            <h1 className="perks-heading">Perks & Benefits</h1>
        </div>

        <div className="perks-grid">

<div className="perks-div">
<div className="perk-icon"><AiOutlineFundProjectionScreen className="officon"/></div>
<div className="perk-text">Project Based Learning</div>
</div>
<div className="perks-div">
<div className="perk-icon"><MdOutlineStickyNote2 className="officon"/></div>
<div className="perk-text">Assignments </div>
</div>
<div className="perks-div">
<div className="perk-icon"><FaTv className="officon"/></div>
<div className="perk-text">Sunday Doubt Classes</div>
</div>
<div className="perks-div">
<div className="perk-icon"><FaConnectdevelop  className="officon"/></div>
<div className="perk-text">Learning Basic to Advance</div>
</div>
<div className="perks-div"><div className="perk-icon"><AiFillSafetyCertificate className="officon"/></div>
<div className="perk-text">Certificates</div></div>
<div className="perks-div"><div className="perk-icon"><FaGift  className="officon"/></div>
<div className="perk-text">Goodies</div></div>
<div className="perks-div"><div className="perk-icon"><FaMoneyBillWave  className="officon"/></div>
<div className="perk-text">Internship </div></div>
<div className="perks-div"><div className="perk-icon"><FaGithub  className="officon"/></div>
<div className="perk-text">Bonus Session on Open Source</div></div>
<div className="perks-div"><div className="perk-icon"><MdOutlineBrandingWatermark  className="officon"/></div>
<div className="perk-text">Bonus Session on Personal Branding</div></div>

<div className="perks-div"><div className="perk-icon"><FaLinkedin  className="officon"/></div>
<div className="perk-text">Bonus Session on Linkedin profile</div></div>

<div className="perks-div"><div className="perk-icon"><FaAddressCard  className="officon" /></div>
<div className="perk-text">Bonus Session on Resume Building</div></div>
        </div>
      </div>


      </div>




      {/*  forth section */}
<div className="project-section">
<div className="project">
<div className="project-heading"><h1 className="heading">Awesome Projects cover in this course </h1></div>


<div className="slide-section">
      {/* <EmployeeSlider/> */}
      <ProjectSlider/>
    </div>
       
        {/* <div className="project-grid">
    <div className="card-div"><img  className="project-card" src={portfolio} alt="" />
    <h3 className="pro-heading"> Apna Portfoliyo</h3></div>
    <div className="card-div"><img className="project-card"  src={pick} alt="" /><h3 className="pro-heading">Pick & Drop service App</h3></div>
    <div className="card-div"><img  className="project-card" src={ecommerse} alt="" /><h3 className="pro-heading">E-commerse Website</h3></div>
   
   
    <div className="card-div"><img  className="project-card" src={food} alt="" /><h3 className="pro-heading"> Online Food Ordering App</h3></div>
    
        </div> */}


{/*  Project information */}


    
   
    
</div>
</div>





{/*  Demo vedio section */}

<div className="demo-vedio__section">
  <div className="vedio-section">
    <div className="demo-heading"><h1 className="heading">Sample Lectures </h1></div>

    <div className="demo-vedio">
    <iframe  src="https://www.youtube.com/embed/yl0BFo7fN_c?si=AYgyNs_3K3FOcN3a" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
    <a href="https://www.youtube.com/@TheCodingShef" style={{ textDecoration:"none"}}><div className="button"><button className="youtube-btn">Checkout on Youtube</button></div></a>
    </div>

  </div>
</div>

{/* Last section Faq  section */}

<div className="question-section">
    <div className="question" >
        <div className="question-heading">
            <h1 className="heading" style={{marginTop:"8rem"}}>FAQ Questions</h1>
        </div>

        {/*  questions list */}
     
        <div className="faq-item">
       <div className="main-question">
       <div onClick={() => toggleQuestion(0)} className="faq-question">
         <h3 className="react-heading"> Will react be coverd in this course</h3>
         <div className="icon"><RiArrowDropDownLine className="icon-dropdown" /></div>

      
        </div>
      
        <AnimatePresence initial={false}>
        {openQuestion === 0 && (
          <motion.div
           
            animate={{ height: "auto", opacity: 1 }}
          
            transition={{ duration: 0.2, ease: "easeInOut" }} className="faq-answer">
           <p className="react">Yes we will cover all react concepts in this course
        </p>
        </motion.div>

        )}
        </AnimatePresence>
       </div>
      </div>
      {/*  */}

      <div className="faq-item">
       <div className="main-question">
       <div onClick={() => toggleQuestion(1)} className="faq-question">
         <h3 className="react-heading"> What are prerequisites of this course</h3>
         <div className="icon"><RiArrowDropDownLine className="icon-dropdown"/></div>
        </div>
      
        <AnimatePresence initial={false}>
        {openQuestion === 1 && (
          <motion.div
               animate={{ height: "auto", opacity: 1 }}
          
          transition={{ duration: 0.2, ease: "easeInOut" }}
           className="faq-answer">
           <p className="react"> A laptop with internet connection, Hunger for Learning. Ready for a grind.

        </p>
        </motion.div>

        )}
        </AnimatePresence>
       </div>
      </div>
     {/*  third */}
     <div className="faq-item">
       <div className="main-question">
       <div onClick={() => toggleQuestion(2)} className="faq-question">
         <h3 className="react-heading"> What language will the course be taught in</h3>
         <div className="icon"><RiArrowDropDownLine className="icon-dropdown"/></div>

        </div>
      
        <AnimatePresence initial={false}>
        {openQuestion === 2 && (
          <motion.div
           
           animate={{ height: "auto", opacity: 1 }}
          
          transition={{ duration: 0.2, ease: "easeInOut" }} className="faq-answer">
           <p className="react"> mixup of Hindi + English
        </p>
        </motion.div>

        )}
        </AnimatePresence>
       </div>
      </div>

      {/* 4orth */}
      <div className="faq-item">
       <div className="main-question">
       <div onClick={() => toggleQuestion(3)} className="faq-question">
         <h3 className="react-heading">Is the course recorded or live</h3>
         <div className="icon"><RiArrowDropDownLine className="icon-dropdown" /></div>

        </div>
        <AnimatePresence initial={false}>
        {openQuestion === 3 && (
          <motion.div
           
           animate={{ height: "auto", opacity:1 }}
          
          transition={{ duration: 0.2, ease: "easeInOut" }} className="faq-answer">
           <p className="react">The course will be live and the recorded lecture we will provide you after class
        </p>
        </motion.div>

        )}
        </AnimatePresence>
       </div>
      </div>

      {/* fifith */}
      <div className="faq-item">
       <div className="main-question">
       <div onClick={() => toggleQuestion(4)} className="faq-question">
         <h3 className="react-heading"> Will you cover node-js </h3>
         <div className="icon-dropdown"><RiArrowDropDownLine className="icon-dropdown" /></div>
        </div>
      
        <AnimatePresence initial={false}>
        {openQuestion === 4 && (
          <motion.div
          
            animate={{ height: "auto" ,opacity:1}}
         
            transition={{ duration: 0.2, ease: "easeOut" }} className="faq-answer">
           <p className="react"> This is an intensive frontend React web series, we will not be covering Node.js.
        </p>
        </motion.div>

        )}
        </AnimatePresence>
       </div>
      </div>
      
        
</div>
</div>



<Footer/>



    </div>
  );
}
