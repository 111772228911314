import React from 'react'
import './Workshoppage.scss'
import Navbar from '../Common/Navbar'
import { useState } from 'react'
import Workshopform from './Workshopform'
import { FcClock } from "react-icons/fc";
import { FcCalendar } from "react-icons/fc";
import { FcMoneyTransfer } from "react-icons/fc";
import { FcGlobe } from "react-icons/fc";

import { motion, AnimatePresence } from 'framer-motion';
import { RiArrowDropDownLine, RiCloseLine } from "react-icons/ri";
import Footer from '../Common/Footer'


const Workshoppage = () => {


  const [openQuestion, setOpenQuestion] = useState(null);
  const [isTermsOpen, setIsTermsOpen] = useState(false);
  const [isPrivacy, setIsPrivacy] = useState(false);
  const[isHelp ,setIsHelp]=useState(false)

  
  const toggleQuestion = (index) => {
    setOpenQuestion(openQuestion === index ? null : index);
  };

  const toggleTermsModal = () => {
    setIsTermsOpen(!isTermsOpen);
   
// setIsHelp(!isHelp)
  };


  const togglePrivacyModel=()=>{
    setIsPrivacy(!isPrivacy)
  }


  const togglecontactModel=()=>{
    setIsHelp(!isHelp)
  }

  return (
    <div className='workshoppage-container'>
    <Navbar/>
      <div className="common">
      <div className="workshoppage">

<div className="workshoppagelive">
<div className="left-workshop"><h1 className='left-first-heading'>Kickstart your Front-End Journey with React:</h1>
<p className='left-para'>A Free 2 hour Webinar</p>
<h2 className="left-sec-heading"> Learn ,Build , Deploy ---- All in Just 2 hours! </h2>

<br />
<p className="left-para-section">Discover the power of web development and begin your front-end journey with React. In this free webinar, you'll create and launch a live React project to share with others.</p>


<div className="workshop-item">
<li className="workshop-list"><FcCalendar  className='workshop-icon'/>Date : 8 Dec-2024</li>
<li className="workshop-list"><FcClock className='workshop-icon' />Time : 7:00 pm</li>
<li className="workshop-list"><FcGlobe  className='workshop-icon'/>Where : Online (Google Meet)</li>
<li className="workshop-list"><FcMoneyTransfer  className='workshop-icon'/>Cost : Free!</li>
</div>

</div>
    {/* <div className="right-workshop">
     <Workshopform/>
    </div> */}
    
</div>
</div>


      </div>
      {/*  about section
       */}

       <div className="about-workshop__section">
        <div className="about-workshop">
          <div className="about-heading"><h1 className="heading">What you'll learn in just 2 Hours</h1></div>


          <div className="workshop-content">
            <div className="html">
            <h3 className="workshop-content__heading">HTML Basics:</h3>
              <li className="content-list"> Elements, Tags, and Semantics.</li>
            </div>
            <div className="css">
            <h3 className="workshop-content__heading">CSS Basics:</h3>
            <li className="content-list"> Selectors, Flexbox, and Grid.</li>

            </div>
            <div className="javascript">
            <h3 className="workshop-content__heading">JavaScript Essentials:</h3>
            <li className="content-list"> DOM Manipulation, Events, and Functions.</li>
            </div>
            <div className="react-content">
            <h3 className="workshop-content__heading">React Basics:</h3>
            <li className="content-list">Understand the building blocks of React like components, props, and state.
</li>
      <li className="content-list">Learn how React makes front-end development easier and faster.</li>

            </div>

            <div className="page">
            <h3 className="workshop-content__heading">Building a Landing Page from Scratch</h3>
            <li className="content-list"> Create a visually appealing, responsive landing page.</li>
            <li className="content-list"> Create a visually appealing, responsive landing page.</li>
            </div>
            <div className="landing">
            <h3 className="workshop-content__heading">Deploying Your First React Project</h3>
            <li className="content-list"> Learn to deploy your landing page live using platforms like Netlify or Vercel.
</li>
<li className="content-list"> Share your deployed app with friends, colleagues, or potential employers.
</li>
            </div>
           
          </div>
        </div>
       </div>



       {/*  webinar */}

       <div className="webinar-section">
        <div className="webinar-content">
          <div className="webinar">
            <h1 className="webinar-heading"><h1 className="heading">Why Join This Webinar?</h1></h1>
          </div>

          <div className="webinar-about__section">
            <p className="webinar-about"> <span className='emojies'>💡</span><em>No Prior Experience Needed :  </em>  Perfect for beginners or those looking to switch to web development. If you know basics
            of html, css and js it would be great.</p>

            <p className="webinar-about"><span className='emojies'>🚀</span> <em>Practical and Hands-On :</em> By the end of the session, youll have a real, live project built by you.</p>
          </div>
        </div>
       </div>


       <div className="attend-section">
        <div className="attend">
          <div className="attend-heading"><h1 className="heading">Who Should Attend ?</h1></div>

          <div className="attend-page">
          
            <li className="content-list"> Aspiring front-end developers looking to start with React
            .</li>
            <li className="content-list"> Beginners eager to build and deploy their first project.
</li>

<li className="content-list"> Students or professionals wanting to add modern web development skills to their toolkit
.</li>
            </div>
        </div>
       </div>



       {/*  reserve spot */}

      <div className="reserve-spot">
      <div className="reserve-section">
        <div className="reserve">
          <div className="attend-heading"><h1 className="reserve-heading"> Reserve Your Spot Now!</h1></div>

          <div className="reserve-page">
          <h3 className="workshop-content__heading">Seats are limited to ensure an interactive and high-quality learning experience. Dont miss out!</h3>
           
           
            </div>
        </div>
       </div>

      </div>


       {/*  faqs */}


      
           
       <div className="question-section">
        <div className="question">
          <div className="question-heading">
            <h1 className="heading" style={{marginTop:"8rem"}}>FAQ's</h1>
          </div>
          <div className="faq-item">
            <div className="main-question">
              <div onClick={() => toggleQuestion(0)} className="faq-question">
                <h3 className="react-heading"> Do I need prior coding knowledge?</h3>
                <div className="icon"><RiArrowDropDownLine className="icon-dropdown" /></div>
              </div>
              <AnimatePresence initial={false}>
                {openQuestion === 0 && (
                  <motion.div
                    animate={{ height: "auto", opacity: 1 }}
                    transition={{ duration: 0.2, ease: "easeInOut" }}
                    className="faq-answer"
                  >
                    <p className="react">Nope! This webinar is beginner-friendly.</p>
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
          </div>
          <div className="faq-item">
       <div className="main-question">
       <div onClick={() => toggleQuestion(1)} className="faq-question">
         <h3 className="react-heading"> How can I deploy my project?</h3>
         <div className="icon"><RiArrowDropDownLine className="icon-dropdown"/></div>
        </div>
      
        <AnimatePresence initial={false}>
        {openQuestion === 1 && (
          <motion.div
               animate={{ height: "auto", opacity: 1 }}
          
          transition={{ duration: 0.2, ease: "easeInOut" }}
           className="faq-answer">
           <p className="react"> Well walk you through every step of deploying your project during the webinar!
           Make sure to join us and take the first step in your front-end development journey! 

        </p>
        </motion.div>

        )}
        </AnimatePresence>
       </div>
      </div>
    
     
      
          {/* Add more FAQs as needed */}
        </div>
      </div>

      {/* Footer section */}
     <Footer/>

    </div>
  )
}

export default Workshoppage
