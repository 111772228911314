import React from 'react'
import { useState } from 'react';
import { RiArrowDropDownLine, RiCloseLine } from "react-icons/ri";
import { useNavigate } from 'react-router-dom';
const Contact = ({togglecontactModel}) => {
   
  return (
    <div>
      <div className="contact-modal">
    <div className="contact-content">
      <RiCloseLine className="close-icon" onClick={ togglecontactModel} />
      <h2>Contact</h2>
      <div className="center-model">
        <p>Have any questions or need more information? We're here to help!</p>
        <p>Reach out to us, and our team will get back to you as soon as possible. Whether you’re looking for support or just want to say hello, we’d love to hear from you!
</p>

<p>  Email: thecodingshef@gmail.com</p>
<p>Operational Address : Gurugram ,Haryana</p>
<a href="https://api.whatsapp.com/send?phone=918279480353"><button className="btn" style={{marginTop:"3rem",backgroundColor:"black"}}>Start chat with us</button></a>
      </div>
    </div>
  </div>
    </div>
  )
}

export default Contact
