import React from 'react'
import './Androidpage.scss'
import Navbar from '../Common/Navbar'
import { useState } from 'react'
import Workshopform from './Workshopform'
import { FcClock } from "react-icons/fc";
import { FcCalendar } from "react-icons/fc";
import { FcMoneyTransfer } from "react-icons/fc";
import { FcGlobe } from "react-icons/fc";

import { motion, AnimatePresence } from 'framer-motion';
import { RiArrowDropDownLine, RiCloseLine } from "react-icons/ri";
import Footer from '../Common/Footer'
import Card from '../Common/Card'
import Android from '../asset/icons/Android.png'
import azim from '../asset/icons/azim.jpeg'
import checked from '../asset/icons/checked2.png'

const Androidpage = () => {


  const [openQuestion, setOpenQuestion] = useState(null);
  const [isTermsOpen, setIsTermsOpen] = useState(false);
  const [isPrivacy, setIsPrivacy] = useState(false);
  const[isHelp ,setIsHelp]=useState(false)

  
  const toggleQuestion = (index) => {
    setOpenQuestion(openQuestion === index ? null : index);
  };

  const toggleTermsModal = () => {
    setIsTermsOpen(!isTermsOpen);
   
// setIsHelp(!isHelp)
  };


  const togglePrivacyModel=()=>{
    setIsPrivacy(!isPrivacy)
  }


  const togglecontactModel=()=>{
    setIsHelp(!isHelp)
  }



  
const cardData = {
  title: " Complete Frontend Development Course (Projects + Roadmap)",

  button:"Book yor Seat",

  price:"₹499",
 
  type: false,
  card:false,
  image: Android,
  link:"https://rzp.io/rzp/ZfZKWR3g",

};

//  android card data

const data=[

 {
  list:"Will discuss topics that you must prepare before your interview.",
 },
 {
  list:"How to prepare for you system design rounds.",
 },
 
  {
    list:"How to make your interview self driven stand out.",
  },
 {
  list:" The top system design questions you need to practice.",
 },
 {
   list:"Tips for Behavioral Round and salary negotiation"
 }
]


const workspecial=[
  { 
    content:"Learn what actually works in real interviews."
  },
  {
    content:" 1-hour packed with actionable advice"
  },
  {
    content:"Ask your questions and get real solutions."
  }
]




  return (
    <div className='workshoppage-container'>
    <Navbar/>
      <div className="commonbg">
      <div className="workshoppage">

<div className="workshoppagelive">
<div className="left-workshop"><h1 className='left-first-heading'>Crack Next Android Interview</h1>
{/* <p className='left-para2'>Crack Your SDE1/SDE2 Interview Like a Pro!</p> */}
{/* <h2 className="left-sec-heading"> Learn ,Build , Deploy ---- All in Just 2 hours! </h2> */}

<br />
<p className="left-para-section2" >Struggling to crack interviews or feeling stuck, confused, and nervous? Whether you're preparing for SDE1, SDE2, or an internship, this workshop will guide you with practical tips and strategies to help you confidently ace your next interview!</p>


<div className="workshop-item">
<li className="workshop-list"><FcCalendar  className='workshop-icon'/>7 December (Saturday)</li>
<li className="workshop-list"><FcClock className='workshop-icon' />4:00 PM - 5:00 PM</li>
{/* <li className="workshop-list"><FcGlobe  className='workshop-icon'/>Where : Online (Google Meet)</li> */}
{/* <li className="workshop-list"><FcMoneyTransfer  className='workshop-icon'/> Fee: ₹499</li> */}
</div>

</div>
    <div className="right-workshop">
    <Card  title={cardData.title}
          price={cardData.price}
          button={cardData.button}
          course={cardData.course}
          point1={cardData.point1}
          point2={cardData.point2}
          point3={cardData.point3}
          point4={cardData.point4}
          point5={cardData.point5}
          image={cardData.image}
            type={cardData.type}
            card={cardData.card}
            link={cardData.link}
          />

    </div>
    
</div>
</div>


      </div>


      <div className="attend-section">
        <div className="attend">
          <div className="attend-heading"><h1 className="heading">What You’ll Learn </h1></div>

    <div className="android-section">
    <motion.div
          className="android-card first"
        
          whileHover={{
            scale: 1.05, // Slightly enlarge on hover
            y: -10, // Move the card up on hover
            boxShadow: "0px 8px 12px rgba(0, 0, 0, 0.2)", // Add a shadow effect
          }}
          transition={{
            duration: 0.3, // Duration of the animation
            ease: "easeInOut", // Easing function
          }}
        >
          <p className="card-para"><img src={checked} className='check-icon' alt="" />An overview about the interview rounds.</p>
        </motion.div>
        <motion.div
          className="android-card second"
       
          whileHover={{
            scale: 1.05, // Slightly enlarge on hover
            y: -10, // Move the card up on hover
            boxShadow: "0px 8px 12px rgba(0, 0, 0, 0.2)", // Add a shadow effect
          }}
          transition={{
            duration: 0.3, // Duration of the animation
            ease: "easeInOut", // Easing function
          }}
        >
          <p className="card-para"><img src={checked} className='check-icon' alt="" /> Discuss topics that you must prepare before your interview.</p>
        </motion.div>
        <motion.div
          className="android-card third"
         
          whileHover={{
            scale: 1.05, // Slightly enlarge on hover
            y: -10, // Move the card up on hover
            boxShadow: "0px 8px 12px rgba(0, 0, 0, 0.2)", // Add a shadow effect
          }}
          transition={{
            duration: 0.3, // Duration of the animation
            ease: "easeInOut", // Easing function
          }}
        >
          <p className="card-para"><img src={checked} className='check-icon' alt="" />How to prepare for you system design rounds.</p>
        </motion.div>
        <motion.div
          className="android-card fourth"
       
          whileHover={{
            scale: 1.05, // Slightly enlarge on hover
            y: -10, // Move the card up on hover
            boxShadow: "0px 8px 12px rgba(0, 0, 0, 0.2)", // Add a shadow effect
          }}
          transition={{
            duration: 0.3, // Duration of the animation
            ease: "easeInOut", // Easing function
          }}
        >
          <p className="card-para"><img src={checked} className='check-icon' alt="" />How to make your interview self driven stand out.</p>
        </motion.div>
        <motion.div
          className="android-card fifth"
          
          whileHover={{
            scale: 1.05, // Slightly enlarge on hover
            y: -10, // Move the card up on hover
            boxShadow: "0px 8px 12px rgba(0, 0, 0, 0.2)", // Add a shadow effect
          }}
          transition={{
            duration: 0.3, // Duration of the animation
            ease: "easeInOut", // Easing function
          }}
        >
          <p className="card-para"><img src={checked} className='check-icon' alt="" />The top system design questions you need to practice.</p>
        </motion.div>
        <motion.div
          className="android-card sixth"
         
          whileHover={{
            scale: 1.05, // Slightly enlarge on hover
            y: -10, // Move the card up on hover
            boxShadow: "0px 8px 12px rgba(0, 0, 0, 0.2)", // Add a shadow effect
          }}
          transition={{
            duration: 0.3, // Duration of the animation
            ease: "easeInOut", // Easing function
          }}
        >
          <p className="card-para"> <img src={checked} className='check-icon' alt="" />Tips for Behavioral Round and salary negotiation</p>
        </motion.div>
    </div>
 



        </div>
       </div>
      {/*  about section
       */}




       <div className="about-workshop__section">
        <div className="about-workshop">
          <div className="about-heading"><h1 className="heading">Why Should You Attend This Workshop?</h1></div>


          <div className="workshop-content">
            <div className="html">
            <h3 className="workshop-content__heading2">Get Insider Secrets: </h3>
              <li className="content-list2"> Learn strategies that top candidates use to stand out.</li>
            </div>
            <div className="css">
            <h3 className="workshop-content__heading2">Commonly Asked Questions Covered:</h3>
            <li className="content-list2"> Master how to confidently approach system design problems.</li>

            </div>
            <div className="javascript">
            <h3 className="workshop-content__heading2">Self-Drive Your Interview:</h3>
            <li className="content-list2"> Learn how to take control and showcase your best self.</li>
            </div>
            <div className="react-content">
            <h3 className="workshop-content__heading2">Behavioral Round Success:</h3>
            <li className="content-list2">Understand why most candidates fail and how you can avoid their mistakes.
</li>
      <li className="content-list2">Learn how React makes front-end development easier and faster.</li>

            </div>

          
          
           
          </div>
        </div>
       </div>


    

       {/*  attend  */}

       <div className="attend-section">
        <div className="attend">
          <div className="attend-heading"><h1 className="heading">Your Mentor</h1></div>

     <div className="android-part">
      <div className="android-left">
      <div className="left-center">
      <img src={azim} className='android-img' alt="" />
      </div>
      </div>
      <div className="android-right">
       <h1 className="android-heading">Azim Siddiqui!</h1>

       <div className="android-list">
        <li className="android-item">4+ years of experience working in top startups and MNCs. Not from even tier 3 college but still making difference in people life by sharing myn experince.</li>
        <li className="android-item">Guide and Mentor to hundreds of students along their carrier and interviews.</li>
        <li className="android-item"> My tips and strategies have made a difference for thousands of aspiring engineers.</li>
       
       </div>

       {/* <div className="company">
        <div className="company1"><img  className="nagarow" src="https://www.gartner.com/pi/vendorimages/nagarro_application-testing-services-worldwide_1657672613360.png" alt="" /></div>
       </div> */}
      </div>
     </div>
        </div>
       </div>



{/* / benefits */}

<div className="attend-section2">
        <div className="attend">
          <div className="attend-heading"><h1 className="heading">What Makes This Workshop Special ? </h1></div>
          <div className="android-section3">
      {
        workspecial.map((ele)=>{
          return(
            <>
            <motion.div
          className="android-card2"
        
          whileHover={{
            scale: 1.05, // Slightly enlarge on hover
            y: -10, // Move the card up on hover
            boxShadow: "0px 8px 12px rgba(0, 0, 0, 0.2)", // Add a shadow effect
          }}
          transition={{
            duration: 0.3, // Duration of the animation
            ease: "easeInOut", // Easing function
          }}
        >
          <p className="card-para"><img src={checked} className='check-icon' alt="" />{ele.content}</p>
          
        </motion.div>
        
    
            </>
          )
        })
      }
        
    </div>
        
        </div>
       </div>



       {/*  reserve spot */}

      <div className="reserve-spot2">
      <div className="reserve-section">
        <div className="reserve">
          <div className="attend-heading"><h1 className="reserve-heading"> Take your First Step Towards Your Dream Job!</h1></div>

          <div className="reserve-page2">
          <h3 className="workshop-content__heading3">Limited seats available , Don’t miss out on this opportunity to transform your preparation.</h3>
           
          {/* <h3 className="workshop-content__heading3">👉 Register Now for ₹499</h3> */}
            </div>
        </div>
       </div>

      </div>


       {/*  faqs */}


      
           
       {/* <div className="question-section">
        <div className="question">
          <div className="question-heading">
            <h1 className="heading" style={{marginTop:"8rem"}}>FAQ's</h1>
          </div>
          <div className="faq-item">
            <div className="main-question">
              <div onClick={() => toggleQuestion(0)} className="faq-question">
                <h3 className="react-heading"> Do I need prior coding knowledge?</h3>
                <div className="icon"><RiArrowDropDownLine className="icon-dropdown" /></div>
              </div>
              <AnimatePresence initial={false}>
                {openQuestion === 0 && (
                  <motion.div
                    animate={{ height: "auto", opacity: 1 }}
                    transition={{ duration: 0.2, ease: "easeInOut" }}
                    className="faq-answer"
                  >
                    <p className="react">Nope! This webinar is beginner-friendly.</p>
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
          </div> */}
          {/* <div className="faq-item">
       <div className="main-question">
       <div onClick={() => toggleQuestion(1)} className="faq-question">
         <h3 className="react-heading"> How can I deploy my project?</h3>
         <div className="icon"><RiArrowDropDownLine className="icon-dropdown"/></div>
        </div>
      
        <AnimatePresence initial={false}>
        {openQuestion === 1 && (
          <motion.div
               animate={{ height: "auto", opacity: 1 }}
          
          transition={{ duration: 0.2, ease: "easeInOut" }}
           className="faq-answer">
           <p className="react"> Well walk you through every step of deploying your project during the webinar!
           Make sure to join us and take the first step in your front-end development journey! 

        </p>
        </motion.div>

        )}
        </AnimatePresence>
       </div>
      </div> */}
    
     
      
          {/* Add more FAQs as needed */}
        {/* </div>
      </div> */}

      {/* Footer section */}
      <Footer/>

    </div>
  )
}

export default Androidpage
